import {LitElement, html, css} from 'lit';

export class FooterElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        position: relative;

        color: rgba(255, 255, 255,0.75);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        
        font-size: 0.5rem;

        width: 100%;

        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;
      }

      .footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.5rem;
      }

      span {
        cursor: pointer;
      }
    `;
  }

  static get properties() {
    return { };
  }


  constructor() {
    super();
  }

  firstUpdated() {

  }

  render() {
    return html`
        <div class="footer">
            <span>credits</span>
            <span>other 1</span>
            <span>other 2</span>
        </div>
    `;
  }
}

window.customElements.define('footer-element', FooterElement);
